(function() {
    'use strict';

    const settingsUi = {
        bindings: {
            practitioner: '=',
        },
        controller: SettingsUiController,
        controllerAs: 'vm',
        templateUrl: 'app/account/settings-practitioner/settings-ui.html',
    };

    angular
        .module('continuumplatformApp')
        .component('settingsUi', settingsUi);

    SettingsUiController.$inject = ['$log', '$scope', 'HealthFacility', 'Practitioner'];

    function SettingsUiController($log, $scope, HealthFacility, Practitioner) {
        const vm = this;

        vm.$onInit = () => {
            loadHealthFacility()
                .then(loadDiseaseDivisions)
                .then(loadTrials)
                .then(loadLocations)
        };

        vm.$onDestroy = () => {
            if(vm.unwatchDiseaseDivisionName) {
                vm.unwatchDiseaseDivisionName();
            }
            if (vm.unwatchTrial) {
                vm.unwatchTrial();
            }
            if (vm.unwatchLocationId) {
                vm.unwatchLocationId();
            }
        };

        function loadHealthFacility() {
            return HealthFacility.get({
                view: 'summary',
                id: vm.practitioner.healthFacilityId
            }).$promise.then(facility => vm.facility = facility);
        }

        function loadDiseaseDivisions() {
            vm.facility.diseaseDivisions = vm.facility.diseaseDivisions || [];
            vm.enableDiseaseDivisionFilter = vm.facility.diseaseDivisions.length > 0;
            if(vm.enableDiseaseDivisionFilter) {

                // group by division name reduce to 1 division by name
                vm.divisions = vm.facility.diseaseDivisions.reduce((divisions, division) => {
                    divisions[division.name] = division.diseaseIds;
                    return divisions;
                }, {});

                vm.diseaseDivisionName = getFilterValue('DISEASE_DIVISION_NAME');

                vm.unwatchDiseaseDivisionName = $scope.$watch('vm.diseaseDivisionName', onChangeDivision);
            }
        }

        function loadTrials() {
            vm.enableTrialFilter = vm.facility.trials.length > 0;
            if(vm.enableTrialFilter) {
                vm.trials = vm.facility.trials;
                vm.trial = getFilterValue('TRIAL');
                vm.unwatchTrial = $scope.$watch('vm.trial', onChangeTrial);
            }
        }

        function loadLocations() {
            if (!vm.facility.features.includes('MULTIPLE_LOCATIONS')) {
                $log.debug('La fonctionnalité multisite n\'est pas activée.');
                return Promise.resolve();
            }
            $log.debug('La fonctionnalité multisite est activée. Chargement des sites...');
            return HealthFacility.query({
                view: 'summary',
                'healthFacilityId.equals': vm.facility.id,
                size: 99, // TODO hard-coded size
            }).$promise.then(locations => {
                const countOfLocations = locations.length;
                $log.debug(`${countOfLocations} site(s) trouvé(s).`);
                vm.enableLocationFilter = countOfLocations > 0;
                if (vm.enableLocationFilter) {
                    vm.locations = locations;
                    vm.locationId = getFilterValue('LOCATION_ID');
                    vm.unwatchLocationId = $scope.$watch('vm.locationId', onChangeLocationId);
                }
            });
        }

        function onChangeDivision(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            setFilterValue('DISEASE_DIVISION_NAME', newVal);
        }

        function onChangeTrial(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            setFilterValue('TRIAL', newVal);
        }

        function onChangeLocationId(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            setFilterValue('LOCATION_ID', newVal);
        }

        function getFilterValue(filter) {
            if (vm.practitioner && vm.practitioner.settings && vm.practitioner.settings.filters)
                return vm.practitioner.settings.filters[filter];
            return undefined;
        }

        function setFilterValue(filter, value) {
            if(value) {
                Practitioner.setFilter({id: vm.practitioner.id, filter}, value);
            } else {
                Practitioner.removeFilter({id: vm.practitioner.id, filter});
            }
        }

    }
})();
