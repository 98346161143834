(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HealthFacilityController', HealthFacilityController);

    HealthFacilityController.$inject = ['$state', '$stateParams', '$uibModal', 'HealthFacility', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function HealthFacilityController($state, $stateParams, $uibModal, HealthFacility, ParseLinks, AlertService, paginationConstants, pagingParams) {

        const vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.create = create;

        vm.currentSearch = $stateParams.search;
        vm.type = $stateParams.type;

        vm.types = HealthFacility.getTypes();

        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            HealthFacility.query({
                view: 'summary',
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                "query": vm.currentSearch,
                "type.equals": vm.type,
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.healthFacilities = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                type: vm.type,
                search: vm.currentSearch
            });
        }

        function clear() {
            vm.currentSearch = null;
            vm.transition();
        }

        function create() {
            $uibModal.open({
                templateUrl: 'app/entities/health-facility/health-facility-dialog.html',
                controller: 'HealthFacilityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: () => ({
                        healthFacilityId: null,
                        name: null,
                        legalName: null,
                        type: null,
                        siret: null,
                        finessEt: null,
                        finessEj: null,
                        phone: null,
                        fax: null,
                        email: null,
                        address: null,
                        postalCode: null,
                        city: null,
                        cityCode: null,
                        country: 'FR',
                        latitude: null,
                        longitude: null,
                        validated: false,
                        status: null,
                        notes: null,
                        commercial: null,
                        id: null
                    })
                }
            }).result.then(result => {
                $state.go('health-facility-detail', {id: result.id});
            }, () => {
                // do nothing
            });
        }
    }
})();
