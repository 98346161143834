(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyListController', PatientTherapyListController);

    PatientTherapyListController.$inject = ['$log', '$scope', '$state', '$stateParams', '$uibModal', '$q', '$window', 'PatientTherapy', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Prescription', 'HealthFacility', 'Principal', 'Practitioner', 'HealthEntityContract', 'PharmacyOrder', 'CareTeam', 'Visit', 'Therapy'];

    function PatientTherapyListController($log, $scope, $state, $stateParams, $uibModal, $q, $window, PatientTherapy, ParseLinks, AlertService, paginationConstants, pagingParams, Prescription, HealthFacility, Principal, Practitioner, HealthEntityContract, PharmacyOrder, CareTeam, Visit, Therapy) {

        const vm = this;

        vm.page = pagingParams.page;
        vm.itemsPerPage = 10;

        vm.savedFilters = {};
        vm.filters = $stateParams.filters;

        vm.statuses = ['S11_VALIDATED', 'S20_ACTIVE', 'S30_PAUSED', 'S40_FINISHED', 'S50_STOPPED', 'S99_CANCELED'];

        vm.applyPaging = applyPaging;
        vm.refreshContentAndFilters = refreshContentAndFilters;
        vm.refreshContent = refreshContent;
        vm.openPractitioner = openPractitioner;
        vm.openFacility = openFacility;

        vm.$onInit = () => {
            Principal.identity().then(account => {

                if (!account.practitioner || !account.practitioner.healthFacilityId) {
                    $log.error('Only practitioners in facilities can use PatientTherapyListController');
                    $state.go('accessdenied');
                    return;
                }

                vm.account = account;
                vm.isAdmin = account.authorities.includes('ROLE_ADMIN');
                vm.practitioner = account.practitioner;
                vm.isDoctor = vm.practitioner.job === 'J10';
                vm.isCoordination = vm.practitioner.healthFacilityType === 'COORDINATION';

                if (angular.isUndefined(vm.filters.assigneeId) && vm.isCoordination) {
                    vm.filters.assigneeId = vm.practitioner.id;
                }

                loadHealthFacility()
                    .then(loadDiseaseDivisions)
                    .then(loadTrials)
                    .then(loadLocations)
                    .then(loadTherapyType)
                    .then(loadAll);
            });

            vm.unwatchStatus = $scope.$watchCollection("vm.filters.status", (oldValue, newValue) => {
                const oldSorted = oldValue.slice().sort();
                if(newValue.length === oldValue.length
                    && newValue.slice().sort().every((value, index) => value === oldSorted[index])) {
                    return;
                }
                refreshContent();
            }, true);

        };

        vm.$onDestroy = () => {
            if (vm.unwatchDiseaseDivisionName) {
                vm.unwatchDiseaseDivisionName();
            }
            if (vm.unwatchTrial) {
                vm.unwatchTrial();
            }
            if (vm.unwatchStatus) {
                vm.unwatchStatus();
            }
            if (vm.unwatchTherapyType) {
                vm.unwatchTherapyType();
            }
            if (vm.unwatchLocationId) {
                vm.unwatchLocationId();
            }
        };

        function loadHealthFacility() {
            return HealthFacility.get({
                view: 'summary',
                id: vm.practitioner.healthFacilityId
            }).$promise.then(facility => vm.facility = facility);
        }

        function loadDiseaseDivisions() {
            vm.facility.diseaseDivisions = vm.facility.diseaseDivisions || [];
            vm.enableDiseaseDivisionFilter = vm.facility.diseaseDivisions.length > 0;
            if(vm.enableDiseaseDivisionFilter) {

                // group by division name reduce to 1 division by name
                vm.divisions = vm.facility.diseaseDivisions.reduce((divisions, division) => {
                    divisions[division.name] = division.diseaseIds;
                    return divisions;
                }, {});

                // restore previously selected division with updated data
                vm.diseaseDivisionName = vm.account.getPractitionerFilter('DISEASE_DIVISION_NAME');
                if(vm.diseaseDivisionName) {
                    if(vm.divisions[vm.diseaseDivisionName]) {
                        vm.filters.diseaseId = undefined;
                        vm.savedFilters.diseaseIds = vm.divisions[vm.diseaseDivisionName];
                    } else {
                        vm.diseaseDivisionName = undefined;
                        vm.savedFilters.diseaseIds = undefined;
                    }
                }
                vm.unwatchDiseaseDivisionName = $scope.$watch('vm.diseaseDivisionName', onChangeDivision);
            }
        }

        function loadTrials() {
            vm.enableTrialFilter = vm.facility.trials.length > 0;
            if(vm.enableTrialFilter) {
                vm.trials = vm.facility.trials;
                vm.savedFilters.trial = vm.account.getPractitionerFilter('TRIAL');
                vm.unwatchTrial = $scope.$watch('vm.savedFilters.trial', onChangeTrial);
            }
        }

        function loadLocations() {
            if (!vm.facility.features.includes('MULTIPLE_LOCATIONS')) {
                $log.debug('La fonctionnalité multisite n\'est pas activée.');
                return Promise.resolve();
            }
            $log.debug('La fonctionnalité multisite est activée. Chargement des sites...');
            return HealthFacility.query({
                view: 'summary',
                'healthFacilityId.equals': vm.facility.id,
                size: 99, // TODO hard-coded size
            }).$promise.then(locations => {
                const countOfLocations = locations.length;
                $log.debug(`${countOfLocations} site(s) trouvé(s).`);
                vm.enableLocationFilter = countOfLocations > 0;
                if (vm.enableLocationFilter) {
                    vm.locations = locations;
                    vm.savedFilters.locationId = vm.account.getPractitionerFilter('LOCATION_ID');
                    vm.unwatchLocationId = $scope.$watch('vm.savedFilters.locationId', onChangeLocationId);
                }
            });
        }

        function loadTherapyType() {
            vm.savedFilters.therapyType = vm.account.getPractitionerFilter('THERAPY_TYPE');
            vm.unwatchTherapyType = $scope.$watch('vm.savedFilters.therapyType', onChangeTherapyType);
        }

        function onChangeLocationId(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('LOCATION_ID', newVal);
            refreshContentAndFilters();
        }

        function onChangeDivision(newVal, oldVal) {
            if(newVal === oldVal) {
                return;
            } else if (newVal) {
                vm.filters.diseaseId = undefined;
                vm.savedFilters.diseaseIds = vm.divisions[newVal];
            } else {
                vm.savedFilters.diseaseIds = undefined;
            }
            Principal.setPractitionerFilter('DISEASE_DIVISION_NAME', newVal);
            refreshContentAndFilters();
        }

        function onChangeTrial(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('TRIAL', newVal);
            refreshContentAndFilters();
        }

        function onChangeTherapyType(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('THERAPY_TYPE', newVal);
            refreshContentAndFilters();
        }

        function refreshContentAndFilters() {
            resetPage().then(loadAll);
        }

        function refreshContent() {
            resetPage().then(loadPatientTherapies);
        }

        function resetPage() {
            vm.page = 1;
            return toPage();
        }

        function loadAll() {

            if (vm.isAdmin) {
                PatientTherapy.queryDistinctFacilities({
                    "status.in": vm.filters.status,
                    "patientStatus.equals": vm.filters.patientStatus === null ? undefined : vm.filters.patientStatus,
                    "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                    "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                    "diseaseId.equals": vm.filters.diseaseId === null ? undefined : vm.filters.diseaseId,
                    "diseaseId.in": vm.savedFilters.diseaseIds,
                    "trial.equals": vm.savedFilters.trial === null ? undefined : vm.savedFilters.trial,
                    "doctorId.equals": vm.filters.prescriberId === null ? undefined : vm.filters.prescriberId,
                    "programId.equals": vm.filters.programId === null ? undefined : vm.filters.programId,
                    "assigneeId.equals": !vm.filters.assigneeId || vm.filters.assigneeId === 'notAssignee' ? undefined : vm.filters.assigneeId,
                    "assigneeId.specified": vm.filters.assigneeId === 'notAssignee' ? false : undefined,
                    "hah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                    "hdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                    locationId: vm.savedFilters.locationId === null ? undefined : vm.savedFilters.locationId,
                }, function (result) {
                    vm.healthfacilities = result;
                });
            }

            PatientTherapy.queryDistinctAssignees({
                "status.in": vm.filters.status,
                "patientStatus.equals": vm.filters.patientStatus === null ? undefined : vm.filters.patientStatus,
                "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                "healthFacilityId.equals": vm.filters.healthFacilityId === null ? undefined : vm.filters.healthFacilityId,
                "diseaseId.equals": vm.filters.diseaseId === null ? undefined : vm.filters.diseaseId,
                "diseaseId.in": vm.savedFilters.diseaseIds,
                "trial.equals": vm.savedFilters.trial === null ? undefined : vm.savedFilters.trial,
                "programId.equals": vm.filters.programId === null ? undefined : vm.filters.programId,
                'doctorId.equals': vm.filters.prescriberId === null ? undefined : vm.filters.prescriberId,
                "hah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                "hdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                locationId: vm.savedFilters.locationId === null ? undefined : vm.savedFilters.locationId,
            }, function (result) {
                vm.assignees = result;

                // Si l'utilisateur fait partie de la cellule d'appui C+ on le rajoute à la liste s'il n'en fait partie
                if (vm.isCoordination) {
                    const isUserInAssignees = vm.assignees.filter((p) => p.id === vm.practitioner.id).length;
                    if (!isUserInAssignees) {
                        vm.assignees.push(vm.practitioner);
                    }
                }
            });

            PatientTherapy.queryDistinctPrescribers({
                "status.in": vm.filters.status,
                "patientStatus.equals": vm.filters.patientStatus === null ? undefined : vm.filters.patientStatus,
                "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                "healthFacilityId.equals": vm.filters.healthFacilityId === null ? undefined : vm.filters.healthFacilityId,
                "diseaseId.equals": vm.filters.diseaseId === null ? undefined : vm.filters.diseaseId,
                "diseaseId.in": vm.savedFilters.diseaseIds,
                "trial.equals": vm.savedFilters.trial === null ? undefined : vm.savedFilters.trial,
                "programId.equals": vm.filters.programId === null ? undefined : vm.filters.programId,
                "assigneeId.equals": !vm.filters.assigneeId || vm.filters.assigneeId === 'notAssignee' ? undefined : vm.filters.assigneeId,
                'assigneeId.specified': vm.filters.assigneeId === 'notAssignee' ? false : undefined,
                "hah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                "hdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                locationId: vm.savedFilters.locationId === null ? undefined : vm.savedFilters.locationId,
            }, function (result) {
                vm.prescribers = result;
            });

            if (!vm.enableDiseaseDivisionFilter) {
                PatientTherapy.queryDistinctDiseases({
                    "status.in": vm.filters.status,
                    "patientStatus.equals": vm.filters.patientStatus === null ? undefined : vm.filters.patientStatus,
                    "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                    "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                    "healthFacilityId.equals": vm.filters.healthFacilityId === null ? undefined : vm.filters.healthFacilityId,
                    "trial.equals": vm.savedFilters.trial === null ? undefined : vm.savedFilters.trial,
                    "doctorId.equals": vm.filters.prescriberId === null ? undefined : vm.filters.prescriberId,
                    "programId.equals": vm.filters.programId === null ? undefined : vm.filters.programId,
                    "assigneeId.equals": !vm.filters.assigneeId || vm.filters.assigneeId === 'notAssignee' ? undefined : vm.filters.assigneeId,
                    "assigneeId.specified": vm.filters.assigneeId === 'notAssignee' ? false : undefined,
                    "hah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                    "hdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                    locationId: vm.savedFilters.locationId === null ? undefined : vm.savedFilters.locationId,
                    "sort": "disease.name",
                }, function (result) {
                    vm.diseases = result;
                });
            }

            PatientTherapy.queryDistinctPrograms({
                "status.in": vm.filters.status,
                "patientStatus.equals": vm.filters.patientStatus === null ? undefined : vm.filters.patientStatus,
                "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                "healthFacilityId.equals": vm.filters.healthFacilityId === null ? undefined : vm.filters.healthFacilityId,
                "doctorId.equals": vm.filters.prescriberId === null ? undefined : vm.filters.prescriberId,
                "diseaseId.equals": vm.filters.diseaseId === null ? undefined : vm.filters.diseaseId,
                "diseaseId.in": vm.savedFilters.diseaseIds,
                "trial.equals": vm.savedFilters.trial === null ? undefined : vm.savedFilters.trial,
                "assigneeId.equals": !vm.filters.assigneeId || vm.filters.assigneeId === 'notAssignee' ? undefined : vm.filters.assigneeId,
                'assigneeId.specified': vm.filters.assigneeId === 'notAssignee' ? false : undefined,
                "hah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                "hdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                locationId: vm.savedFilters.locationId === null ? undefined : vm.savedFilters.locationId,
            }, function (result) {
                vm.programs = result;
            });

            loadPatientTherapies();
        }

        function loadPatientTherapies() {

            if (vm.isLoading) {
                $log.warn('Already loading, skipped.');
                return;
            }

            vm.isLoading = Date.now();
            vm.isLoadingPatientTherapies = true;
            vm.error = false;
            vm.patientTherapies = null;

            $log.debug('Loading page', vm.page);
            PatientTherapy.query({
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: ['status', 'startDate,desc', 'id,desc'],
                "status.in": vm.filters.status,
                "patientStatus.equals": vm.filters.patientStatus === null ? undefined : vm.filters.patientStatus,
                "patientPostalCode.startsWith": !vm.filters.patientPostalCode ? undefined : vm.filters.patientPostalCode,
                "patientCity.containsNormalized": !vm.filters.patientCity ? undefined : vm.filters.patientCity,
                "diseaseId.equals": vm.filters.diseaseId === null ? undefined : vm.filters.diseaseId,
                "diseaseId.in": vm.savedFilters.diseaseIds,
                "trial.equals": vm.savedFilters.trial === null ? undefined : vm.savedFilters.trial,
                "healthFacilityId.equals": vm.filters.healthFacilityId === null ? undefined : vm.filters.healthFacilityId,
                "doctorId.equals": vm.filters.prescriberId === null ? undefined : vm.filters.prescriberId,
                "programId.equals": vm.filters.programId === null ? undefined : vm.filters.programId,
                "assigneeId.equals": !vm.filters.assigneeId || vm.filters.assigneeId === 'notAssignee' ? undefined : vm.filters.assigneeId,
                "assigneeId.specified": vm.filters.assigneeId === 'notAssignee' ? false : undefined,
                "hah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                "hdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                locationId: vm.savedFilters.locationId === null ? undefined : vm.savedFilters.locationId,
            }, onSuccess, onError).$promise.finally(() => {
                vm.isLoadingPatientTherapies = false;
            });

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.patientTherapies = data;

                // TODO tuning (too many requests)
                const promises = [];
                angular.forEach(vm.patientTherapies, patientTherapy => {

                    patientTherapy.prescriptions = Prescription.query({
                        "patientTherapyId.equals": patientTherapy.id,
                        "sort": ["date,desc", "id,desc"]
                    });
                    if (vm.isAdmin) {
                        patientTherapy.pharmacyOrders = PharmacyOrder.query({
                            "patientTherapyId.equals": patientTherapy.id
                        });
                    }

                    // récupération de la date prévue du 1er questionnaire
                    promises.push(loadPlannedDateFirstVisit(patientTherapy));

                    // team
                    patientTherapy.team = [];
                    promises.push(CareTeam.getMembers(patientTherapy.patient.id)
                        .then(response => processTeam(response, patientTherapy)));
                });

                $q.all(promises).finally(() => {
                    const elapsed = Date.now() - vm.isLoading;
                    vm.isLoading = null;
                    $log.info('Loading took ' + elapsed + 'ms');
                });
            }

            function processTeam(response, patientTherapy) {

                const promises = [];
                const contractExecutorIds = [];
                const team = response.data
                    .filter(m => ['MAIN_DOCTOR', 'DOCTOR', 'PHARMACY', 'NURSE', 'NURSE_PLUS', 'NURSING', 'HAH', 'NURSING_HOME'].includes(m.role))
                    .sort(CareTeam.sort);

                team.forEach((m, i) => {
                    patientTherapy.team.push(m);
                    if (['MAIN_DOCTOR', 'PHARMACY', 'NURSE', 'NURSE_PLUS', 'NURSING', 'HAH', 'NURSING_HOME'].includes(m.role)) {
                        contractExecutorIds.push(m.entity.id);
                    }
                    if (['NURSING', 'HAH', 'NURSING_HOME'].includes(m.role)) {
                        promises.push(Practitioner.query({
                            "job.equals": "J60",
                            "healthFacilityId.equals": m.entity.id,
                            "sort": "lastName,asc"
                        }, (nurses) => {

                            angular.forEach(nurses, nurse => {
                                if (nurse.legalStatus === 'LIBERAL')
                                    contractExecutorIds.push(nurse.id);
                                patientTherapy.team.push({
                                    role: 'NURSE',
                                    type: 'Practitioner',
                                    entity: nurse
                                });
                            });

                            $log.debug(`${patientTherapy.id}: loaded ${nurses.length} nurses`);
                        }).$promise);
                    }
                    if (['PHARMACY'].includes(m.role)) {
                        promises.push(Practitioner.query({
                            "job.equals": "J21",
                            "healthFacilityId.equals": m.entity.id,
                            "sort": "lastName,asc"
                        }, (pharmacists) => {

                            angular.forEach(pharmacists, pharmacist => {
                                contractExecutorIds.push(pharmacist.id);

                                // on ajoute le pharmacien juste après dans la liste
                                // pour l'afficher à la suite de pharmacy au niveau de l'affichage
                                patientTherapy.team.splice(i + 1, 0, {
                                    role: 'PHARMACIST',
                                    type: 'Practitioner',
                                    entity: pharmacist
                                });
                            });

                            $log.debug(`${patientTherapy.id}: loaded ${pharmacists.length} pharmacists`);
                        }).$promise);
                    }
                });

                return $q.all(promises)
                    .then(() => loadContracts(patientTherapy, contractExecutorIds));
            }

            function onError(error) {
                $log.error('Unable to load patients', error);
                vm.isLoading = null;
                vm.error = true;
            }

            function loadContracts(patientTherapy, executorIds) {
                if (!executorIds || !executorIds.length || !patientTherapy.contractLineId) {
                    return Promise.resolve();
                }
                return HealthEntityContract.query({
                    "executorId.in": executorIds,
                    "signed": false,
                    "matchesContractLineId": patientTherapy.contractLineId
                }, contracts => {
                    patientTherapy.contracts = {};
                    for (const hec of contracts) {
                        patientTherapy.contracts[hec.executorId] = false;
                    }

                    $log.debug(`${patientTherapy.id}: loaded ${contracts.length} contracts to sign`);
                }).$promise;
            }
        }

        function applyPaging() {
            toPage().then(loadPatientTherapies);
        }

        function toPage() {
            return $state.go($state.$current, {
                page: vm.page,
            });
        }

        function openPractitioner(practitioner) {

            Principal.hasAuthority('ROLE_ADMIN').then(hasAuthority => {
                if (hasAuthority) {
                    const url = $state.href('practitioner-detail', {id: practitioner.id});
                    $window.open(url, 'practitioner-detail-' + practitioner.id);
                } else {
                    return $uibModal.open({
                        templateUrl: 'app/entities/practitioner/practitioner-modal.html',
                        controller: 'PractitionerModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            practitioner: () => practitioner
                        }
                    });
                }
            });
        }

        function openFacility(facility) {
            Principal.hasAuthority('ROLE_ADMIN').then(hasAuthority => {
                if (hasAuthority) {
                    const url = $state.href('health-facility-detail', {id: facility.id});
                    $window.open(url, 'health-facility-detail-' + facility.id);
                } else {
                    return $uibModal.open({
                        templateUrl: 'app/entities/health-facility/health-facility-modal.html',
                        controller: 'HealthFacilityModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            healthFacility: () => facility
                        }
                    });
                }
            });
        }

        function loadPlannedDateFirstVisit(patientTherapy) {
            // Les types de visites à prendre en compte pour la première visite
            const VALID_VISIT_TYPES = ['PRO', 'STD_HOME', 'PHARMA_ITW', 'MEDICAL_CONSULT_EXT', 'NCC_PHONE', 'NCH_PHONE'];

            switch (patientTherapy.status) {
            case 'S11_VALIDATED' : // parcours à démarrer

                if (!patientTherapy.therapyId || !patientTherapy.day1Date) {
                    return;
                }

                return Therapy.get({id: patientTherapy.therapyId})
                    .$promise
                    .then((therapy) => {

                        // Trie les visites par la date relative d'échéance
                        therapy.plan.visits.sort((visit1, visit2) => visit1.relativeDueDateDays - visit2.relativeDueDateDays );

                        // Trouve la première visite à prendre en compte selon le type de visite
                        const firstRelevantVisit = therapy.plan.visits.find(visit => VALID_VISIT_TYPES.includes(visit.type));

                        patientTherapy.dueDateFirstVisit = moment(patientTherapy.day1Date).add(firstRelevantVisit.relativeDueDateDays, 'days').toDate();

                        $log.debug(`${patientTherapy.id}: loaded first visit ${patientTherapy.dueDateFirstVisit}`);
                    });

            case 'S20_ACTIVE' : // parcours en cours

                return Visit.query({
                    "patientTherapyId.equals": patientTherapy.id,
                    "type.in": VALID_VISIT_TYPES,
                    sort: ['dueDate', 'asc'],
                    size: 1
                }).$promise.then((visits) => {

                    if (visits && visits.length) {
                        const firstVisit = visits[0];

                        if (['TODO', 'IN_PROGRESS'].includes(firstVisit.status)) {
                            patientTherapy.dueDateFirstVisit = firstVisit.dueDate;
                        }

                    }

                    $log.debug(`${patientTherapy.id}: loaded first visit ${patientTherapy.dueDateFirstVisit}`);
                });
            }

        }

    }
})();
