(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientController', PatientController);

    PatientController.$inject = ['$log', '_', '$filter', '$scope', '$state', '$stateParams', 'ParseLinks', 'paginationConstants', 'pagingParams', 'Principal', 'Patient', 'HealthFacility', 'Inbox', 'CareTeam'];

    function PatientController($log, _, $filter, $scope, $state, $stateParams, ParseLinks, paginationConstants, pagingParams, Principal, Patient, HealthFacility, Inbox, CareTeam) {

        const vm = this;

        vm.savedFilters = {};
        vm.filters = $stateParams.filters;
        vm.currentSearch = $stateParams.search;
        vm.advancedSearch = $stateParams.advancedSearch;

        vm.page = pagingParams.page;
        vm.predicate = pagingParams.predicate;
        vm.ascending = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.open = open;
        vm.applyPagingAndSorting = applyPagingAndSorting;
        vm.clear = clear;
        vm.toggleAdvancedSearch = toggleAdvancedSearch;
        vm.search = search;
        vm.refreshContentAndFilters = refreshContentAndFilters;
        vm.refreshContent = refreshContent;

        vm.unwatchIdentity = $scope.$watchGroup(['vm.filters.familyName', 'vm.filters.givenName', 'vm.filters.birthDate'], (newValues, oldValues) => {
            newValues[0] = newValues[0] || undefined;
            newValues[1] = newValues[1] || undefined;
            newValues[2] = newValues[2] || undefined;
            if (_.isEqual(newValues, oldValues)) {
                return;
            }
            const familyName = newValues[0];
            const givenName = newValues[1];
            const birthDate = newValues[2];
            if (birthDate || familyName || givenName) {
                vm.filters.ins = null;
                vm.filters.internalId = null;
            }
            refreshContent();
        });

        vm.unwatchIns = $scope.$watch('vm.filters.ins', (newVal, oldVal) => {
            if (newVal) {
                vm.filters = {
                    ins: newVal
                };
                refreshContent();
            }
        });

        vm.unwatchInternalId = $scope.$watch('vm.filters.internalId', (newVal, oldVal) => {
            if (oldVal && !newVal) {
                refreshContent();
            }
        });

        vm.$onInit = () => {

            Principal.identity().then(account => {

                vm.account = account;
                vm.practitioner = account.practitioner;

                vm.hasAccessToHealthData = account.authorities.includes('PATIENT_HEALTH_READ');

                vm.isHospitalUser = vm.practitioner.healthFacilityType === 'HOSPITAL';

                if (account.authorities.includes('ROLE_ADMIN')) {
                    vm.isAdmin = true;
                } else if (vm.practitioner.job === 'J10') {
                    vm.isDoctor = true;
                    if (vm.isHospitalUser) {
                        if (angular.isUndefined(vm.filters.facilityDoctorId)) {
                            vm.filters.facilityDoctorId = vm.practitioner.id;
                        }
                        vm.forceFacilityDoctor = vm.practitioner;
                    }
                } else if (vm.practitioner.job === 'J60') {
                    vm.isNurse = true;
                } else if (['J21', 'J38'].includes(vm.practitioner.job)) {
                    vm.isPharmacist = true;
                }

                if (angular.isUndefined(vm.filters.facilityDoctorId)) {
                    vm.filters.facilityDoctorId = null;
                }
                if (angular.isUndefined(vm.filters.nurseCoordId)) {
                    vm.filters.nurseCoordId = null;
                }
                if (angular.isUndefined(vm.filters.facilityPharmacistId)) {
                    vm.filters.facilityPharmacistId = null;
                }

                loadSavedFilters(account).then(loadAll);
            });

            loadUnread();
        };

        vm.$onDestroy = () => {
            if(vm.unwatchIdentity) {
                vm.unwatchIdentity();
            }
            if(vm.unwatchIns) {
                vm.unwatchIns();
            }
            if(vm.unwatchInternalId) {
                vm.unwatchInternalId();
            }
            if (vm.unwatchDiseaseDivisionName) {
                vm.unwatchDiseaseDivisionName();
            }
            if (vm.unwatchTrial) {
                vm.unwatchTrial();
            }
            if (vm.unwatchLocationId) {
                vm.unwatchLocationId();
            }
            if(vm.unwatchTherapyType) {
                vm.unwatchTherapyType();
            }
        };

        function loadSavedFilters() {
            if (!vm.practitioner.healthFacilityId) {
                vm.enableDiseaseDivisionFilter = false;
                vm.diseaseDivisionName = undefined;
                vm.savedFilters.diseaseIds = undefined;

                vm.enableTrialFilter = false;
                vm.savedFilters.trial = undefined;

                vm.enableLocationFilter = false;
                vm.savedFilters.locationId = undefined;
                return Promise.resolve();
            }
            return loadHealthFacility()
                .then(loadDiseaseDivisions)
                .then(loadTrials)
                .then(loadLocations)
                .then(loadTherapyType)
        }

        function loadHealthFacility() {
            return HealthFacility.get({
                view: 'summary',
                id: vm.practitioner.healthFacilityId
            }).$promise.then(facility => vm.facility = facility);
        }

        function loadDiseaseDivisions() {
            vm.facility.diseaseDivisions = vm.facility.diseaseDivisions || [];
            vm.enableDiseaseDivisionFilter = vm.facility.diseaseDivisions.length > 0;
            if(vm.enableDiseaseDivisionFilter) {

                // group by division name reduce to 1 division by name
                vm.divisions = vm.facility.diseaseDivisions.reduce((divisions, division) => {
                    divisions[division.name] = division.diseaseIds;
                    return divisions;
                }, {});

                // restore previously selected division with updated data
                vm.diseaseDivisionName = vm.account.getPractitionerFilter('DISEASE_DIVISION_NAME');
                if(vm.diseaseDivisionName) {
                    if(vm.divisions[vm.diseaseDivisionName]) {
                        vm.filters.diseaseId = undefined;
                        vm.savedFilters.diseaseIds = vm.divisions[vm.diseaseDivisionName];
                    } else {
                        vm.account.removePractitionerFilter('DISEASE_DIVISION_NAME');
                        vm.diseaseDivisionName = undefined;
                        vm.savedFilters.diseaseIds = undefined;
                    }
                }
                vm.unwatchDiseaseDivisionName = $scope.$watch('vm.diseaseDivisionName', onChangeDivision);
            }
        }

        function loadTrials() {
            vm.enableTrialFilter = vm.facility.trials.length > 0;
            if(vm.enableTrialFilter) {
                vm.trials = vm.facility.trials;
                vm.savedFilters.trial = vm.account.getPractitionerFilter('TRIAL');
                vm.unwatchTrial = $scope.$watch('vm.savedFilters.trial', onChangeTrial);
            }
        }

        function loadLocations() {
            if (!vm.facility.features.includes('MULTIPLE_LOCATIONS')) {
                $log.debug('La fonctionnalité multisite n\'est pas activée.');
                return Promise.resolve();
            }
            $log.debug('La fonctionnalité multisite est activée. Chargement des sites...');
            return HealthFacility.query({
                view: 'summary',
                'healthFacilityId.equals': vm.facility.id,
                size: 99, // TODO hard-coded size
            }).$promise.then(locations => {
                const countOfLocations = locations.length;
                $log.debug(`${countOfLocations} site(s) trouvé(s).`);
                vm.enableLocationFilter = countOfLocations > 0;
                if (vm.enableLocationFilter) {
                    vm.locations = locations;
                    vm.savedFilters.locationId = vm.account.getPractitionerFilter('LOCATION_ID');
                    vm.unwatchLocationId = $scope.$watch('vm.savedFilters.locationId', onChangeLocationId);
                }
            });
        }

        function loadTherapyType() {
            vm.savedFilters.therapyType = vm.account.getPractitionerFilter('THERAPY_TYPE');
            vm.unwatchTherapyType = $scope.$watch('vm.savedFilters.therapyType', onChangeTherapyType);
        }

        function onChangeLocationId(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('LOCATION_ID', newVal);
            refreshContentAndFilters();
        }

        function onChangeDivision(newVal, oldVal) {
            if(newVal === oldVal) {
                return;
            } else if (newVal) {
                vm.filters.diseaseId = undefined;
                vm.savedFilters.diseaseIds = vm.divisions[newVal];
            } else {
                vm.savedFilters.diseaseIds = undefined;
            }
            Principal.setPractitionerFilter('DISEASE_DIVISION_NAME', newVal);
            refreshContentAndFilters();
        }

        function onChangeTrial(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('TRIAL', newVal);
            refreshContentAndFilters();
        }

        function onChangeTherapyType(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('THERAPY_TYPE', newVal);
            refreshContentAndFilters();
        }

        function refreshContentAndFilters() {
            resetPage().then(loadAll);
        }

        function refreshContent() {
            resetPage().then(loadPatients);
        }

        function resetPage() {
            vm.page = 1;
            return toPage();
        }

        function loadAll() {
            $log.debug('Loading filters');
            if (vm.isAdmin) {
                loadDistinctFacilities();
            }
            loadDistinctPrograms();
            loadDistinctFacilityDoctors();
            loadDistinctFacilityPharmacists();
            loadDistinctNurseCoords();
            if(!vm.enableDiseaseDivisionFilter) {
                loadDistinctDiseases();
            }
            loadPatients();
        }

        function loadDistinctFacilities() {
            Patient.queryDistinctFacilities({
                "sort": "healthFacility.name,asc",
                "diseaseId.equals": vm.filters.diseaseId || undefined,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
                "patientTherapyHdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                "patientTherapyHah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                "patientTherapyTrial.equals": vm.savedFilters.trial || undefined,
                "patientTherapyProgramId.equals": vm.filters.programId || undefined,
                "locationId": vm.savedFilters.locationId || undefined,
            }, (result) => {
                vm.healthfacilities = result;
            });
        }

        function loadDistinctPrograms() {
            Patient.queryDistinctPrograms({
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
                "nurseCoordId": vm.filters.nurseCoordId || undefined,
                "diseaseId.equals": vm.filters.diseaseId || undefined,
                "diseaseId.in": vm.savedFilters.diseaseIds,
                "patientTherapyHdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                "patientTherapyHah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                "patientTherapyTrial.equals": vm.savedFilters.trial || undefined,
                "locationId": vm.savedFilters.locationId || undefined,
            }, (result) => {
                vm.programs = result;
            });
        }

        function loadDistinctFacilityDoctors() {
            Patient.queryDistinctFacilityDoctors({
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
                "nurseCoordId": vm.filters.nurseCoordId || undefined,
                "diseaseId.equals": vm.filters.diseaseId || undefined,
                "diseaseId.in": vm.savedFilters.diseaseIds,
                "patientTherapyHdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                "patientTherapyHah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                "patientTherapyTrial.equals": vm.savedFilters.trial || undefined,
                "patientTherapyProgramId.equals": vm.filters.programId || undefined,
                "locationId": vm.savedFilters.locationId || undefined,
            }, (result) => {
                vm.facilityDoctors = result;
                if (!result.length && vm.forceFacilityDoctor) {
                    vm.facilityDoctors.push(vm.forceFacilityDoctor);
                }
            });
        }

        function loadDistinctFacilityPharmacists() {
            Patient.queryDistinctFacilityPharmacists({
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "nurseCoordId": vm.filters.nurseCoordId || undefined,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "diseaseId.equals": vm.filters.diseaseId || undefined,
                "diseaseId.in": vm.savedFilters.diseaseIds,
                "patientTherapyHdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                "patientTherapyHah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                "patientTherapyTrial.equals": vm.savedFilters.trial || undefined,
                "patientTherapyProgramId.equals": vm.filters.programId || undefined,
                "locationId": vm.savedFilters.locationId || undefined,
            }, (result) => {
                vm.pharmacists = result;
            });
        }

        function loadDistinctNurseCoords() {
            Patient.queryDistinctNurseCoords({
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
                "diseaseId.equals": vm.filters.diseaseId || undefined,
                "diseaseId.in": vm.savedFilters.diseaseIds,
                "patientTherapyHdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                "patientTherapyHah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                "patientTherapyTrial.equals": vm.savedFilters.trial || undefined,
                "patientTherapyProgramId.equals": vm.filters.programId || undefined,
                "locationId": vm.savedFilters.locationId || undefined,
            }, (result) => {
                vm.nurseCoords = result;
            });
        }

        function loadDistinctDiseases() {
            Patient.queryDistinctDiseases({
                "sort": "disease.name",
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "nurseCoordId": vm.filters.nurseCoordId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "patientTherapyHdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                "patientTherapyHah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                "patientTherapyTrial.equals": vm.savedFilters.trial || undefined,
                "patientTherapyProgramId.equals": vm.filters.programId || undefined,
                "locationId": vm.savedFilters.locationId || undefined,
            }, function (result) {
                vm.diseases = result;
            });
        }

        function loadPatients() {
            vm.isLoading = true;
            vm.error = false;
            vm.patients = null;
            $log.debug('Loading patients page', vm.page);
            Patient.query({
                view: 'full',
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                "query": vm.currentSearch || undefined,
                "firstNameStartsWith": vm.filters.givenName || undefined,
                "lastNameOrBirthNameStartsWith": vm.filters.familyName || undefined,
                "gender.equals": vm.filters.gender || undefined,
                "birthDate.equals": vm.filters.birthDate ? $filter('date')(vm.filters.birthDate, 'yyyy-MM-dd') : undefined,
                "ins": vm.filters.ins || undefined,
                "internalId": vm.filters.internalId || undefined,
                "diseaseId.equals": vm.filters.diseaseId || undefined,
                "diseaseId.in": vm.savedFilters.diseaseIds,
                "healthFacilityId.equals": vm.filters.healthFacilityId || undefined,
                "nurseCoordId": vm.filters.nurseCoordId || undefined,
                "facilityDoctorId": vm.filters.facilityDoctorId || undefined,
                "facilityPharmacistId": vm.filters.facilityPharmacistId || undefined,
                "patientTherapyHdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                "patientTherapyHah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                "patientTherapyTrial.equals": vm.savedFilters.trial || undefined,
                "patientTherapyProgramId.equals": vm.filters.programId || undefined,
                "locationId": vm.savedFilters.locationId || undefined,
            }, (data, headers) => {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.patients = data.map(mapLastEventBy);
            }, () => {}).$promise.catch(error => {
                $log.error('Unable to load patients', error);
                vm.error = true;
            }).finally(() => vm.isLoading = false);

            function sort() {
                const result = [vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function mapLastEventBy(patient) {
                let lastEventByRoleType;
                if (patient.lastEventByRole) {
                    const lastEventByRoleRules = CareTeam.rules[patient.lastEventByRole];
                    lastEventByRoleType = lastEventByRoleRules && lastEventByRoleRules.type;
                }
                if (lastEventByRoleType === 'Practitioner') {
                    patient.lastEventByLabelKey1 = 'continuumplatformApp.patient.teamRole.' + patient.lastEventByRole;
                } else if (patient.lastEventByJob) {
                    patient.lastEventByLabelKey1 = 'continuumplatformApp.practitioner.job.' + patient.lastEventByJob;
                    if (patient.lastEventByFacilityType) {
                        patient.lastEventByLabelKey2 = 'continuumplatformApp.practitioner.facilityTypeQualifier.' + patient.lastEventByFacilityType;
                    }
                } else if (patient.lastEventType === 'NEW_MESSAGE') {
                    patient.lastEventByLabelKey1 = 'continuumplatformApp.patient.teamRole.PATIENT';
                }

                return patient;
            }
        }

        function applyPagingAndSorting() {
            $log.debug('Paging and sorting')
            toPage().then(loadPatients);
        }

        function toPage() {
            return $state.go($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc'),
                search: vm.currentSearch,
                advancedSearch: vm.advancedSearch,
                filters: vm.filters
            });
        }

        function search() {
            if (!vm.currentSearch) {
                return vm.clear();
            }
            vm.links = null;
            vm.refreshContent();
        }

        function clear() {
            vm.links = null;
            vm.currentSearch = null;
            vm.refreshContent();
        }

        function toggleAdvancedSearch() {
            vm.advancedSearch = !vm.advancedSearch;
            if (vm.currentSearch !== null || !vm.advancedSearch) {
                clear();
            }
        }

        function open(id) {
            vm.isOpening = true;
            let target;
            if (vm.isPharmacist) {
                target = 'patient-detail.pharmaceutics';
            } else if (vm.hasAccessToHealthData) {
                target = 'patient-detail.follow-up';
            } else {
                target = 'patient-detail.team';
            }
            $state.go(target, {id: id});
        }

        function loadUnread() {
            Inbox.getSummary().then(function (response) {
                vm.unread = {};
                angular.forEach(response.data, function (value) {
                    vm.unread[value.patientId] = {
                        countUnread: value.countUnread,
                        countUnreadImportant: value.countUnreadImportant
                    };
                });
            });
        }
    }
})();
