(function () {
    'use strict';

    var patientTeamPanel = {
        bindings: {
            patient: '=',
            editable: '<',
            showHeader: '='
        },
        controller: PatientTeamPanelController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient/patient-team-panel.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientTeamPanel', patientTeamPanel);

    PatientTeamPanelController.$inject = ['$rootScope', '$state', '$uibModal', '$window', 'Patient', 'Principal', 'CareTeam', 'HealthFacility'];

    function PatientTeamPanelController($rootScope, $state, $uibModal, $window, Patient, Principal, CareTeam, HealthFacility) {

        var vm = this;

        vm.$onInit = () => {
            Principal.identity().then(account => {
                vm.account = account;
                loadPatientHealthFacility().then(load);
            });
        };

        vm.canModify = canModify;
        vm.canRemove = canRemove;
        vm.select = select;
        vm.remove = remove;

        vm.openHealthFacility = openHealthFacility;

        vm.openPractitioner = openPractitioner;

        function loadPatientHealthFacility() {
            return HealthFacility.get({
                view: 'summary',
                id: vm.patient.healthFacilityId
            }).$promise.then(facility => vm.patientFacility = facility);
        }

        function load() {
            CareTeam.getMembers(vm.patient.id).then(response => {
                vm.team = response.data.sort(CareTeam.sort);
                vm.coordinationFacilityIds = response.data.filter(m => m.role === 'COORDINATION_FACILITY').map(m => m.entity.id);

                vm.addOptions = CareTeam.roles
                    .filter(role => {

                        // limite du nombre de membres par rôle
                        const limit = CareTeam.rules[role].limit;
                        if(limit && vm.team.filter(m => m.role === role).length >= limit) {
                            return false;
                        }

                        // restriction à l'administrateur
                        const restrictedToAdmin = CareTeam.rules[role].admin;
                        if(restrictedToAdmin && !vm.account.authorities.includes('ROLE_ADMIN')) {
                            return false;
                        }

                        // ajout de site seulement si établissement multi-site
                        const multipleLocationsEnabled = vm.patientFacility.features.includes('MULTIPLE_LOCATIONS');
                        return multipleLocationsEnabled || role !== 'LOCATION';
                    });
            });
        }

        function canModify(role, entity) {
            if (entity.healthFacilityId === vm.patient.healthFacilityId) {
                return vm.account.practitioner
                    && [...vm.coordinationFacilityIds, vm.patient.healthFacilityId].includes(vm.account.practitioner.healthFacilityId);
            }
            if (['NURSING',
                'NURSE',
                'NURSE_PLUS',
                'NURSE_REGION',
                'ANALYSIS_LABORATORY',
                'PV_CENTER',
                'COORDINATION_FACILITY'].includes(role)) {
                return vm.account.authorities.includes('ROLE_ADMIN');
            }
            return true;
        }

        function canRemove(role, entity) {
            if (role === 'LOCATION') {
                return vm.team.filter(m => m.role === 'LOCATION').length > 1;
            }
            return canModify(role, entity);
        }

        function openFacilitySelection(healthFacilityType) {
            return $uibModal.open({
                templateUrl: 'app/entities/health-facility/health-facility-selection.html',
                controller: 'HealthFacilitySelectionController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    pagingParams: [function () {
                        return {
                            predicate: 'name',
                            ascending: true
                        };
                    }],
                    search: [function () {
                        return {
                            name: null,
                            address: null,
                            postalCode: vm.patient.postalCode,
                            city: vm.patient.city
                        };
                    }],
                    healthFacilityType: [function () {
                        return healthFacilityType;
                    }],
                    parentHealthFacilityId: [function () {
                        return null;
                    }]
                }
            });
        }

        function openLocationSelection(parentId) {
            return $uibModal.open({
                templateUrl: 'app/entities/health-facility/health-facility-selection.html',
                controller: 'HealthFacilitySelectionController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    pagingParams: [() => ({
                        predicate: 'name',
                        ascending: true,
                    })],
                    search: [() => ({})],
                    healthFacilityType: [() => 'HOSPITAL'],
                    parentHealthFacilityId: [() => parentId]
                }
            });
        }

        function openHealthFacility(healthFacilityId) {
            Principal.hasAuthority('ROLE_ADMIN').then(function (hasAuthority) {
                if (hasAuthority) {
                    $state.go('health-facility-detail', {id: healthFacilityId});
                } else {
                    return $uibModal.open({
                        templateUrl: 'app/entities/health-facility/health-facility-modal.html',
                        controller: 'HealthFacilityModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            healthFacility: ['HealthFacility', function (HealthFacility) {
                                return HealthFacility.get({id: healthFacilityId, view: 'summary'}).$promise;
                            }]
                        }
                    });
                }
            });
        }

        function openPractitionerSelection(search, restrict) {
            return $uibModal.open({
                templateUrl: 'app/entities/practitioner/practitioner-selection.html',
                controller: 'PractitionerSelectionController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    pagingParams: [function () {
                        return {
                            predicate: 'lastName',
                            ascending: true
                        };
                    }],
                    search: [function () {
                        return search;
                    }],
                    restrict: [function () {
                        return restrict;
                    }]
                }
            });
        }

        function openPractitionerInvitation(job) {
            return $uibModal.open({
                templateUrl: 'app/entities/practitioner/practitioner-dialog.html',
                controller: 'PractitionerDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: {
                        job: job,
                        speciality: null,
                        category: null,
                        firstName: null,
                        lastName: null,
                        adeli: null,
                        rpps: null,
                        siret: null,
                        iban: null,
                        phone: null,
                        mobilePhone: null,
                        email: null,
                        address: null,
                        postalCode: null,
                        city: null,
                        latitude: null,
                        longitude: null,
                        cityCode: null,
                        country: 'FR',
                        legalRegistrationNumber: null,
                        legalStatus: null,
                        legalSubStatus: null,
                        notes: null,
                        userAuthType: null,
                        validated: true,
                        notify: true
                    },
                    options: {
                        mode: 'addTeam',
                        searchCriteria: {
                            address: vm.patient.address,
                            postalCode: vm.patient.postalCode,
                            city: vm.patient.city,
                            latitude: vm.patient.latitude,
                            longitude: vm.patient.longitude,
                            legalStatus: null
                        }
                    }
                }
            });
        }

        function openPractitioner(practitioner) {

            Principal.hasAuthority('ROLE_ADMIN').then(function (hasAuthority) {
                if (hasAuthority) {
                    const url = $state.href('practitioner-detail', {id: practitioner.id});
                    $window.open(url, 'practitioner-detail-' + practitioner.id);
                } else {
                    return $uibModal.open({
                        templateUrl: 'app/entities/practitioner/practitioner-modal.html',
                        controller: 'PractitionerModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            practitioner: function () {
                                return practitioner;
                            }
                        }
                    });
                }
            });
        }

        function emitPatientUpdate() {
            $rootScope.$emit('continuumplatformApp:patientTeamUpdate');
            load();
        }

        function select(role, replaceId) {
            let modal;
            let notification = false;
            switch (role) {

            case 'HOSPITAL':
                modal = openFacilitySelection('HOSPITAL');
                break;

            case 'LOCATION':
                modal = openLocationSelection(vm.patient.healthFacilityId);
                break;

            case 'FACILITY_DOCTOR':
                modal = openPractitionerSelection(null, {
                    job: 'J10',
                    healthFacilityId: vm.patient.healthFacilityId
                });
                notification = true;
                break;

            case 'FACILITY_PHARMACIST':
                modal = openPractitionerSelection(null,
                    {
                        job: 'J21',
                        healthFacilityId: vm.patient.healthFacilityId
                    });
                notification = true;
                break;

            case 'NURSE_COORD':
                modal = openPractitionerSelection(null, {
                    job: 'J60',
                    healthFacilityId: vm.patient.healthFacilityId
                });
                notification = true;
                break;

            case 'MAIN_DOCTOR':
                modal = openPractitionerInvitation('J10');
                notification = true;
                break;

            case 'DOCTOR':
                modal = openPractitionerInvitation('J10');
                notification = true;
                break;

            case 'PHARMACY':
                modal = openFacilitySelection('PHARMACY');
                break;

            case     'NURSING':
                modal = openFacilitySelection('NURSING');
                break;

            case     'NURSE':
                modal = openPractitionerInvitation('J60');
                notification = true;
                break;

            case     'NURSE_PLUS':
                modal = openPractitionerSelection({
                    postalCode: vm.patient.postalCode ? vm.patient.postalCode.substring(0, 2) : null
                }, {
                    job: 'J60',
                    badge: 'akoadom-nurse-plus'
                });
                notification = true;
                break;

            case     'NURSE_REGION':
                modal = openPractitionerSelection(null, {
                    job: 'J60'
                });
                notification = true;
                break;

            case     'HAH':
                modal = openFacilitySelection('HAH');
                break;

            case     'NURSING_HOME':
                modal = openFacilitySelection('NURSING_HOME');
                break;

            case     'ANALYSIS_LABORATORY':
                modal = openFacilitySelection('ANALYSIS_LABORATORY');
                break;

            case     'PV_CENTER':
                modal = openFacilitySelection('PV');
                break;

            case     'COORDINATION_FACILITY':
                modal = openFacilitySelection('COORDINATION');
                break;
            }

            modal
                .result
                .then(id => replaceId
                    ? replace(role, replaceId, id, notification)
                    : add(role, id, notification));
        }

        function add(role, entityId, notification) {
            CareTeam.addMember(vm.patient.id, role, entityId, notification)
                .then(emitPatientUpdate);
        }

        function replace(role, entityId, byId, notification) {
            CareTeam.replaceMember(vm.patient.id, role, entityId, byId, notification)
                .then(emitPatientUpdate);
        }

        function remove(role, entityId) {
            CareTeam.removeMember(vm.patient.id, role, entityId)
                .then(emitPatientUpdate);
        }

    }

})();
