(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('ExpiredController', ExpiredController);

    ExpiredController.$inject = ['$scope', '$state', '$log', '$uibModal', 'paginationConstants', 'pagingParams', 'filters', 'ParseLinks', 'Principal', 'Visit', 'DownloadFile', 'HealthFacility', 'Practitioner'];

    function ExpiredController($scope, $state, $log, $uibModal, paginationConstants, pagingParams, filters, ParseLinks, Principal, Visit, DownloadFile, HealthFacility, Practitioner) {

        const vm = this;

        vm.savedFilters = {};
        vm.filters = filters;

        vm.page = pagingParams.page;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.visitTypes = null;

        vm.applyPaging = applyPaging;

        vm.$onInit = () => {

            getAccount().then(() => {

                vm.visitTypes = getVisitTypeForUser();

                loadHealthFacility()
                    .then(loadDiseaseDivisions)
                    .then(loadTrials)
                    .then(loadLocations)
                    .then(loadTherapyType)
                    .then(() => {

                        vm.unwatchVisitType = $scope.$watch('vm.filters.type', onChangeFilter);
                        if (vm.isCoordination) {
                            loadUnicorns();
                        }

                        loadVisits();
                    });

            });
        };

        vm.$onDestroy = () => {
            if (vm.unwatchDiseaseDivisionName) {
                vm.unwatchDiseaseDivisionName();
            }
            if (vm.unwatchTrial) {
                vm.unwatchTrial();
            }
            if (vm.unwatchLocationId) {
                vm.unwatchLocationId();
            }
            if(vm.unwatchTherapyType) {
                vm.unwatchTherapyType();
            }
            if (vm.unwatchVisitType) {
                vm.unwatchVisitType();
            }
            if (vm.unwatchFeasibleByPractitionerId) {
                vm.unwatchFeasibleByPractitionerId();
            }
        };

        function loadHealthFacility() {
            return HealthFacility.get({
                view: 'summary',
                id: vm.account.practitioner.healthFacilityId,
            }).$promise.then(facility => vm.facility = facility);
        }

        function loadDiseaseDivisions() {
            vm.facility.diseaseDivisions = vm.facility.diseaseDivisions || [];
            vm.enableDiseaseDivisionFilter = vm.facility.diseaseDivisions.length > 0;
            if (vm.enableDiseaseDivisionFilter) {

                // group by division name reduce to 1 division by name
                vm.divisions = vm.facility.diseaseDivisions.reduce((divisions, division) => {
                    divisions[division.name] = division.diseaseIds;
                    return divisions;
                }, {});

                // restore previously selected division with updated data
                vm.diseaseDivisionName = vm.account.getPractitionerFilter('DISEASE_DIVISION_NAME');
                if (vm.diseaseDivisionName) {
                    if (vm.divisions[vm.diseaseDivisionName]) {
                        vm.filters.diseaseId = undefined;
                        vm.savedFilters.diseaseIds = vm.divisions[vm.diseaseDivisionName];
                    } else {
                        vm.diseaseDivisionName = undefined;
                        vm.savedFilters.diseaseIds = undefined;
                    }
                }
                vm.unwatchDiseaseDivisionName = $scope.$watch('vm.diseaseDivisionName', onChangeDivision);
            }
        }

        function loadTrials() {
            vm.enableTrialFilter = vm.facility.trials.length > 0;
            if(vm.enableTrialFilter) {
                vm.trials = vm.facility.trials;
                vm.savedFilters.trial = vm.account.getPractitionerFilter('TRIAL');
                vm.unwatchTrial = $scope.$watch('vm.savedFilters.trial', onChangeTrial);
            }
        }

        function loadLocations() {
            if (!vm.facility.features.includes('MULTIPLE_LOCATIONS')) {
                $log.debug('La fonctionnalité multisite n\'est pas activée.');
                return Promise.resolve();
            }
            $log.debug('La fonctionnalité multisite est activée. Chargement des sites...');
            return HealthFacility.query({
                view: 'summary',
                'healthFacilityId.equals': vm.facility.id,
                size: 99, // TODO hard-coded size
            }).$promise.then(locations => {
                const countOfLocations = locations.length;
                $log.debug(`${countOfLocations} site(s) trouvé(s).`);
                vm.enableLocationFilter = countOfLocations > 0;
                if (vm.enableLocationFilter) {
                    vm.locations = locations;
                    vm.savedFilters.locationId = vm.account.getPractitionerFilter('LOCATION_ID');
                    vm.unwatchLocationId = $scope.$watch('vm.savedFilters.locationId', onChangeLocationId);
                }
            });
        }

        function loadTherapyType() {
            vm.savedFilters.therapyType = vm.account.getPractitionerFilter('THERAPY_TYPE');
            vm.unwatchTherapyType = $scope.$watch('vm.savedFilters.therapyType', onChangeTherapyType);
        }

        function onChangeLocationId(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('LOCATION_ID', newVal);
            loadVisits();
        }

        function onChangeDivision(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            } else if (newVal) {
                vm.filters.diseaseId = undefined;
                vm.savedFilters.diseaseIds = vm.divisions[newVal];
            } else {
                vm.savedFilters.diseaseIds = undefined;
            }
            Principal.setPractitionerFilter('DISEASE_DIVISION_NAME', newVal);
            refreshContent();
        }

        function onChangeTrial(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('TRIAL', newVal);
            refreshContent();
        }

        function onChangeTherapyType(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('THERAPY_TYPE', newVal);
            refreshContent();
        }

        function loadUnicorns() {
            if (angular.isUndefined(vm.filters.patientTherapyAssigneeId)) {
                vm.filters.patientTherapyAssigneeId = vm.account.practitioner.id;
            }
            Practitioner.query({
                'healthFacilityId.equals': vm.account.practitioner.healthFacilityId,
                size: 1000,
                sort: ['lastName,asc'],
            }).$promise.then((unicorns) => {
                vm.unicorns = unicorns;
                vm.unwatchFeasibleByPractitionerId = $scope.$watch('vm.filters.patientTherapyAssigneeId', onChangeFilter);
            });
        }

        /**
         * Get the user account.
         * @return {*} a promise with the user account.
         */
        function getAccount() {
            return Principal.identity().then(account => {

                if (!account || !account.practitioner || !account.practitioner.healthFacilityId) {
                    $log.error('Only practitioners in facilities can use ExpiredController');
                    $state.go('accessdenied');
                    return;
                }

                vm.isCoordination = account.practitioner.healthFacilityType === 'COORDINATION';
                vm.isHospitalUser = account.practitioner.healthFacilityType === 'HOSPITAL';

                vm.practitioner = account.practitioner;
                vm.isDoctor = account.practitioner.job === 'J10';
                vm.isPharmacist = account.practitioner.job === 'J21';
                vm.isNurse = account.practitioner.job === 'J60';
                vm.isOtherPractitioner = account.practitioner.job === 'J99';

                return vm.account = account;
            });
        }

        function refreshContent() {
            resetPage().then(loadVisits);
        }

        function resetPage() {
            vm.page = 1;
            return toPage();
        }

        function loadVisits() {
            vm.isLoading = true;
            vm.visits = null;
            $log.debug('Loading visits page', vm.page)
            Visit.query({
                "status.equals": 'EXPIRED',
                "patientTherapyAssigneeId.equals": vm.filters.patientTherapyAssigneeId || undefined,
                "patientTherapyStatus.in": ['S20_ACTIVE', 'S30_PAUSED'],
                "diseaseId.in": getDiseaseIdIn(),
                "type.in": vm.filters.type ? [vm.filters.type] : vm.visitTypes,
                "trial.equals": vm.savedFilters.trial || undefined,
                "hdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                "hah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                locationId: vm.savedFilters.locationId || undefined,
                coordinator: true,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: ['orderingDate,desc', 'id,desc'],
            }, onSuccess).$promise.finally(() => {
                vm.isLoading = false;
            });

        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.visits = data;
        }

        function applyPaging() {
            $log.debug('Paging and sorting')
            toPage().then(loadVisits);
        }

        function toPage() {
            return $state.go($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc'),
                filters: vm.filters
            });
        }

        function getDiseaseIdIn() {
            if (vm.savedFilters.diseaseIds && vm.savedFilters.diseaseIds.length) {
                return vm.savedFilters.diseaseIds;
            }
            return undefined;
        }

        function onChangeFilter(newVal, oldVal) {
            if (angular.equals(newVal, oldVal)) {
                return;
            }
            refreshContent();
        }

        /**
         * Generate the visit types list available for the user
         * @return {*[]} - an array of the visit types
         */
        function getVisitTypeForUser() {
            if (vm.isHospitalUser) {
                return [
                    'PRO',
                    'PHARMA_ITW',
                    'MEDICAL_CONSULT_EXT'
                ];
            }
            return Visit.visitTypes;
        }

    }
})();
